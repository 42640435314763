import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import SendToken from "../views/SendToken.vue";

const routes = [{
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/sendtoken",
        name: "SendToken",
        component: SendToken,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;